<template>
  <p>
    Redirecting to
    <router-link to="/auth/login">Login</router-link>
    ...
  </p>
</template>

<script>
import LocalStore from '@/util/LocalStore'

export default {
  mounted() {
    if (this.$store.getters['auth/loggedIn']) {
      this.logout()
    }
  },
  methods: {
    logout() {
      LocalStore.logout()
      this.$store.dispatch('auth/logoutUser')
    }
  }
}
</script>
