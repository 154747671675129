<template>
  <div id="logout">
    <Headline />
    <AuthLogout />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import AuthLogout from '../../components/auth/AuthLogout'

export default {
  components: {
    Headline,
    AuthLogout
  }
}
</script>
